import {checkPasswordStatusApi, setPasswordApi, updatePasswordApi} from '@/api/login'

export default {
	data() {
		const checkPassword = (rule, value, callback) => {

			if(!value) {
				callback(new Error('请输入密码'))
			} else if(value.length < 6 || value.length > 32) {
				callback(new Error('密码长度应为6~32个字符'))
			} else {
				callback()
			}
		}

		const checkRePassword = (rule, value, callback) => {

			if(!value) {
				callback(new Error('请再次输入密码'))
			} else if(value !== this.setForm.password) {
				callback(new Error('两次密码输入不一致'))
			} else {
				callback()
			}
		}
		return {
            isPassword: 0,
			showDialog: false,
			isUpdate: true,
			updateForm: {},
			setForm: {},
			updateRule: {
				oldPassword: [{required: true, validator: checkPassword, trigger: 'blur'}],
				newPassword: [{required: true, validator: checkPassword, trigger: 'blur'}],
			},
			setRule: {
				password: [{required: true, validator: checkPassword, trigger: 'blur'}],
				rePassword: [{required: true, validator: checkRePassword, trigger: 'blur'}]
			}
		}
	},

	computed: {

	},

	mounted() {
		this.checkPasswordStatus()
	},

	methods: {

		closeDialog() {
			this.showDialog = false
			if(this.isUpdate) {
				this.$refs.updateForm.clearValidate()
			}else {
				this.$refs.setForm.clearValidate()
			}
		},

		onSubmit() {
			if(this.isUpdate) {
				this.onUpdatePassword()
			}else {
				this.onSetPassword()
			}
		},

		onUpdatePassword() {
			this.$refs.updateForm.validate(valid => {
				if(valid) {
					updatePasswordApi(this.updateForm).then(res => {
						this.$message.success(res.msg)
						this.closeDialog()
						this.onOutLogin()
					}).catch(err => {
						this.$message.error(err.msg)
					})
				}
			})
		},

		onSetPassword() {
			this.$refs.setForm.validate(valid => {
				if(valid) {
					setPasswordApi(this.setForm).then(res => {
						this.$message.success(res.msg)
						this.closeDialog()
					}).catch(err => {
						this.$message.error(err.msg)
					})
				}
			})
		},

		checkPasswordStatus() {
			checkPasswordStatusApi().then(res => {
				if(res.code === 1) {
					this.isPassword = res.data.is_password
				}
			})
		},

		onBack() {
			this.$router.push({
				path: '/'
			})
		},

        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        onUserCommand (e) {
            if(e === 3){
                // 退出登录
                this.onOutLogin()
            }else if(e === 2) {
                // 设置密码
				this.showDialog = true
				this.setForm = {}
				this.isUpdate = false
            }else if(e === 1) {
                // 修改密码
				this.showDialog = true
				this.updateForm = {}
				this.isUpdate = true
            }
        },
        onOutLogin () {
			this.$store.dispatch('outLogin').then(() => {
				this.$router.push('/')
			})
        },
	}
}