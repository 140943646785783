import LayoutMain from "@/layout/layout-main";

const routePer = 'news'

const newsRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `/home/${routePer}list`,
			component: () => import('@/views/news/list'),
			name: 'NewsList',
			meta: {
				title: '资讯列表'
			}
		},
		{
			path: `/home/${routePer}detail`,
			component: () => import('@/views/news/detail'),
			name: 'NewsDetail',
			meta: {
				title: '资讯详情'
			}
		}
	]
}

export default newsRouter;