<template>
	<footer class="footer">

		<div class="info flexRow aCenter">

			<div class="flex-1 box">

				<div class="title">联系我们</div>

				<div class="floor">公司名称：{{ info.about.company }}</div>

				<div class="floor">公司邮箱：{{ info.about.email }}</div>

				<div class="floor">联系电话：{{ info.about.mobile }}</div>

				<div class="floor">公司地址：{{ info.about.address }}</div>

			</div>

			<div class="flex-1 box">

				<div class="title">战略伙伴</div>

				<div class="cooperation">

					<div class="cooperation-item" v-for="item in info.cooperation" :key="item.id">{{item.name}}</div>

				</div>

			</div>

			<div class="flex-1 box">

				<div class="title">友情链接</div>

				<div class="cooperation">

					<div class="cooperation-item pointer" v-for="item in info.link" :key="item.id" @click="openHref(item)">{{item.name}}</div>

				</div>

			</div>

			<div class="flex-1 box flexRow jBetween wrap">

				<div class="code">

					<img class="qrcode" :src="info.about.gzh" />

					<div>微信公众号</div>

				</div>

				<div class="code">

					<img class="qrcode" :src="info.about.xcx" />

					<div>微信小程序</div>

				</div>


			</div>

		</div>

		<div class="filing">

			<div class="box flexRow center">

				<span>Copyright</span>

				<span>©2024</span>

				<span>云帮大商城</span>

<!--				<a href="https://beian.miit.gov.cn/">{{info.about.record_number}}</a>-->
				<div class="pointer" @click="openHref({url: 'https://beian.miit.gov.cn/'})">{{info.about.record_number}}</div>

			</div>

		</div>

	</footer>
</template>

<script>
import mixins from '../mixins'
export default {
	name: "LayoutFooter",
	mixins
}
</script>

<style scoped lang="scss" src="../style.scss"></style>