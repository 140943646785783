import setting from "@/setting";


export default {
	data() {
		return {
			address: '',
			loginDialog: false,
			mainHeight: 0,
			scrollTop: 0,
			wxAppId: 'wxa70f6eb2a7c88cc5',
			wxRedirectUri: setting.wxRedirectUri,
			wxHref: 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIGJvcmRlcjogbm9uZTsNCiAgd2lkdGg6IDIwMHB4Ow0KICBoZWlnaHQ6IDIwMHB4Ow0KfQ0KLmltcG93ZXJCb3ggLnN0YXR1c3sNCiAgZGlzcGxheTogbm9uZQ0KfQ=='
		}
	},

	watch: {
		'$route'(to, form) {
			this.$refs.container.scrollTop = 0;
		}
	},

	computed: {
		pageHeight() {
			return document.body.clientHeight;
		},
		isFixed() {
			return this.scrollTop > 100;
		},
		userDialog() {
			if(this.$store.getters.isUser) {
				return this.$store.getters.isUser != 1;
			} else {
				return false
			}
		}
	},

	created() {
		this.$store.dispatch('config');
	},

	mounted() {
		this.mainHeight = this.pageHeight - 340;
	},

	methods: {
		closeUserDialog() {
			this.$store.commit('SET_IS_USER', null)
			window.location.href = setting.wxRedirectUri
		},

		openLoginDialog() {

			this.$router.push({
				path: '/login'
			})

			// this.loginDialog = true;



			// const loading = this.$loading({
			// 	lock: true,
			// 	text: '正在登录',
			// 	spinner: 'el-icon-loading',
			// 	background: 'rgba(0, 0, 0, 0.7)'
			// })
			// store.dispatch('login').then(res => {
			//
			// }).catch(err => {
			//
			// }).finally(() =>{
			// 	// window.location.href = window.location.href.split(window.location.hash)[0].split('?')[0]
			// 	loading.close()
			// })
		},

		handleScroll(e) {
			const { scrollTop, scrollHeight, clientHeight} = e.target;

			this.scrollTop = scrollTop;

			this.$children.forEach(child => {
				// 商品列表下拉加载
				if(child.$route.name === 'productList') {
					const isBottom = scrollHeight - (scrollTop + clientHeight + 40) <= 1; // 判断是否到达底部
					if(isBottom && child.hasMore && !child.loading) {
						child.loadMore();
					}
				}
			})
		}
	}
}