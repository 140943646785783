import LayoutMain from "@/layout/layout-main";

const routePer = '/shop'

const shopRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/shop/list'),
			name: 'shopList',
			meta: {
				title: '店铺列表'
			}
		},
		{
			path: `${routePer}/detail`,
			component: () => import('@/views/shop/detail'),
			name: 'shopDetail',
			meta: {
				title: '店铺详情'
			}
		}
	]
}

export default shopRouter;