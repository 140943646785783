import LayoutMain from "@/layout/layout-main";

const routePer = '/active'

const activeRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/active/list'),
			name: 'ActiveList',
			meta: {
				title: '活动列表'
			}
		},
		{
			path: `${routePer}/detail`,
			component: () => import('@/views/active/detail'),
			name: 'ActiveDetail',
			meta: {
				title: '活动详情'
			}
		}
	]
}

export default activeRouter;