const formatTime = date => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()

	return `${[year, month, day].map(formatNumber).join('-')} ${[hour, minute, second].map(formatNumber).join(':')}`
}

const formatDate = date => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	return `${[year, month, day].map(formatNumber).join('-')}`
}

const formatNumber = n => {
	n = n.toString()
	return n[1] ? n : `0${n}`
}


/**
 * 截取code
 * @param name
 * @param url
 * @returns {string | null}
 */
const getUrlKey = (name,url) => {
	// url = url || window.location.href
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || null
};

const base64ToFile = (base64Data, filename) => {
	// 将base64的数据部分提取出来
	const parts = base64Data.split(';base64,');
	const contentType = parts[0].split(':')[1];
	const raw = window.atob(parts[1]);

	// 将原始数据转换为Uint8Array
	const rawLength = raw.length;
	const uInt8Array = new Uint8Array(rawLength);
	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	// 使用Blob对象创建File对象
	const blob = new Blob([uInt8Array], {type: contentType});
	blob.lastModifiedDate = new Date();
	blob.name = filename;

	return new File([blob], filename, {type: contentType});
}

export default {
	formatTime,
	formatDate,
	getUrlKey,
	base64ToFile
}