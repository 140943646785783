import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Loading } from 'element-ui';

import homeRouter from "./modules/home";
import adminRouter from "./modules/admin";
import marketRouter from "./modules/market";
import shopRouter from "./modules/shop";
import activeRouter from "./modules/active";
import chamberRouter from "./modules/chamber";
import newsRouter from "./modules/news";
import schoolRouter from "./modules/school";
import choiceRouter from "./modules/choice";
import productRouter from "./modules/product"

import util from "@/utils/util";
import setting from "@/setting";

Vue.use(VueRouter)

const routes = [
	homeRouter,
	marketRouter,
	adminRouter,
	shopRouter,
	activeRouter,
	chamberRouter,
	newsRouter,
	schoolRouter,
	choiceRouter,
	productRouter,
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login'),
		meta: {
			title: '登录'
		}
	}
]

const router = new VueRouter({
	routes,
})

router.beforeEach((to, from, next) => {
	// console.log(to)
	// next()
	// 判断是否登录
	let code = util.getUrlKey('code', window.location.href)

	// 未登录
	if(!store.getters.isLogin) {
		if(code) {
			let hash = window.location.hash;
			const loading = Loading.service({
				lock: true,
				text: '正在登录',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			store.dispatch('login', code).then(res => {
				next()
			}).finally(() => {
				loading.close()
			})
		}else {
			next()
		}
	}else {
		if(code) {
			window.location.href = `${setting.wxRedirectUri}/#${to.fullPath}`
			next()
		}else {
			next()
		}
	}

})

router.afterEach((to, form) => {
	document.title = `${to.meta.title}-云帮大商城`
})

export default router
