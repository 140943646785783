
const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.yunbang777.com' : '/api/'

// api.yunbang777.com

// lysc.yamabang.cn

export default {
	// 项目名称
	name: '云帮大商城',
	// 域名
	baseUrl: BASE_URL,
	// 图片地址前缀
	imageBaseUrl: 'https://yunbang.oss-cn-hangzhou.aliyuncs.com',
	// 验证码倒计时 单位：秒
	countDown: 60,
	// 微信授权回调地址
	wxRedirectUri: 'https://www.yunbang777.com',
	// wxRedirectUri: 'https://test.yunbang777.com',
}