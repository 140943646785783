export default function () {
	return [
		{
			title: '首页',
			path: '/',
			type: 1,			// 1、本项目 2、外部 0、无链接
			routeKey: 'home',
		},
		// {
		// 	name: '商会服务',
		// 	path: '/chamber/list',
		// 	routeKey: 'chamber',
		// 	auth: false,
		// },
		// {
		// 	name: '商户列表',
		// 	path: '/shop/list',
		// 	routeKey: 'shop',
		// 	auth: false,
		// },
		// {
		// 	name: '在线展会',
		// 	path: '/active/list',
		// 	routeKey: 'active',
		// 	auth: true,
		// },
		// {
		// 	name: '在线招标',
		// 	path: '',
		// 	auth: true,
		// },
		// {
		// 	name: '永不落幕展厅',
		// 	path: '',
		// 	href: 'https://yun.kujiale.com/cloud/design/3FO3RGY605P0/show',
		// 	auth: false,
		// },
		// {
		// 	name: '在线物流',
		// 	path: '',
		// 	auth: false,
		// },
		// {
		// 	name: '共享云仓',
		// 	path: '',
		// 	auth: false,
		// },
		// {
		// 	name: '供应链金融',
		// 	path: '',
		// 	auth: false,
		// }
	]
}