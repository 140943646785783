import LayoutMain from "@/layout/layout-main";

const routePer = '/product'

const shopRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/product/list'),
			name: 'productList',
			meta: {
				title: '产品列表'
			}
		},
		{
			path: `${routePer}/detail/:id`,
			component: () => import('@/views/product/detail'),
			name: 'productDetail',
			meta: {
				title: '产品详情'
			}
		}
	]
}

export default shopRouter;