import test from "@/utils/test";

export default {
	data() {

		const checkMobile = (ruel, value, callback) => {
			if(!test.regPhone(value)) {
				callback(new Error('请输入合法的手机号码'))
			}else {
				callback()
			}
		}

		const checkAddress = (rule, value, callback) => {
			if(!value || value.length < 3) {
				callback(new Error('请选择所在地'))
			} else {
				callback()
			}
		}

		return {
			rules: {
				avatar: [{required: true, message: '请上传头像', trigger: 'change'}],
				nickname: [{required: true, message: '请输入姓名', trigger: 'blur'}],
				mobile: [{required: true, validator: checkMobile, trigger: 'blur'}],
				captcha: [{required: true, message: "请输入短信验证码", trigger: 'blur'}],
				addressArr: [{required: true, validator: checkAddress, trigger: 'change'}]
			}
		}
	}
}