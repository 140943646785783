import LayoutMain from "@/layout/layout-main";

const routePer = '/choice'

const shopRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/choice/list'),
			name: 'choiceShopList',
			meta: {
				title: '精选地产品'
			}
		},
		{
			path: `${routePer}/detail`,
			component: () => import('@/views/choice/detail'),
			name: 'choiceShopDetail',
			meta: {
				title: '精选地产品'
			}
		},
		{
			path: `${routePer}/proxyDetail`,
			component: () => import('@/views/choice/proxyDetail'),
			name: 'ProxyDetail',
			meta: {
				title: '代理招募详情'
			}
		}
	]
}

export default shopRouter;