import config from "@/setting";

export default {

    handleImage: function (src, baseURL) {
        baseURL = baseURL || config.imageBaseUrl;
        if (typeof src === 'string') {
            if (src.indexOf('http') < 0) {
                return baseURL + src;
            }
            else {
                return src;
            }
        } else if (src !== null && typeof src === 'object') {
            for (var key in src) {
                if (src.hasOwnProperty(key)) {
                    src[key] = this.handleImage(src[key], baseURL);
                }
            }
            return src;
        }
        else {
            return src;
        }
    },
    replace(src,value='', baseURL) {
        baseURL = baseURL || config.imageBaseUrl;
        return src.replace(baseURL,value);
    }

}
