import request from "@/utils/request";

/**
 * 微信登录
 * @param code
 * @returns {void|*}
 */
export function wxLogin(code) {
	return request.post('api/login/pcWxLogin', {code})
}

/**
 * 账号密码登录
 * @param data
 * @returns {void|*}
 */
export function accountLogin(data) {
	return request.post("api/login/passwordLogin", data);
}

/**
 * 验证码登录
 * @param data
 * @returns {void|*}
 */
export function codeLogin(data) {
	return request.post("api/login/smsLogin", data);
}

/**
 * 注册
 * @param data
 * @returns {void|*}
 */
export function register(data) {
	return request.post("api/login/pcRegister", data);
}

/**
 * 完善用户信息
 * @param data
 * @returns {void|*}
 */
export function updateUserApi(data) {
	return request.post("api/login/addPcWxUserInfo", data);
}

/**
 * 忘记密码
 * @param data
 * @returns {void|*}
 */
export function forgetPasswordApi(data) {
	return request.post("api/account/retrievePassword", data);
}

/**
 * 修改密码
 * @param data
 * @returns {*}
 */
export function updatePasswordApi(data) {
	return request.post("api/account/changePassword", data);
}

/**
 * 第一次设置密码
 * @param data
 * @returns {void|*}
 */
export function setPasswordApi(data) {
	return request.post('api/account/setPassword', data);
}

/**
 * 检查是否设置过密码
 * @returns {*}
 */
export function checkPasswordStatusApi() {
	return request.post("api/account/checkPassword", {});
}