import Vue from 'vue'
import Vuex from 'vuex'
import {getToken, setToken, removeToken, getUser, setUser, removeUser} from '@/utils/auth'
import {defaultLogin} from '@/api/admin'
import {configApi} from '@/api/comm'
import setting from "@/setting";
import router from "@/router";
import {register, accountLogin, codeLogin, wxLogin, updateUserApi} from "@/api/login";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: getToken(),
		isLogin: !!getToken(),
		userinfo: getUser(),
		isUser: null,
		config: JSON.parse(localStorage.getItem('config')),
		openid: null,
		unionid: null
	},
	getters: {
		isLogin: state => state.isLogin,
		token: state => state.token,
		userinfo: state => state.userinfo,
		isUser: state => state.isUser,
		openid: state => state.openid,
		unionid: state => state.unionid
	},
	mutations: {
		// 设置token
		'SET_TOKEN': (state,token) => {
			setToken(token)
			state.token = token
		},
		// 设置用户信息
		'SET_USER': (state,user) => {
			setUser(user)
			state.userinfo = user
		},
		// 设置用户登录状态
		'SET_IS_LOGIN': (state, isLogin) => {
			localStorage.setItem('isLogin', isLogin);
			state.isLogin = isLogin
		},
		// 设置配置信息
		'SET_CONFIG': (state, config) => {
			localStorage.setItem('config', JSON.stringify(config));
			state.config = config
		},
		'SET_IS_USER': (state, isUser) => {
			localStorage.setItem('isUser', isUser);
			state.isUser = isUser;
  		},
		'SET_OPENID': (state, openid) => {
			state.openid = openid;
		},
		'SET_UNIONID': (state, unionid) => {
			state.unionid = unionid;
		}
	},
	actions: {
		// 微信登录
		login({commit}, code) {
			return new Promise((resolve, reject) => {
				wxLogin(code).then(res => {
					let statusInfo = res.data.statusInfo || {};
					let userInfo = res.data.userInfo;
					if(statusInfo.is_new) {
						commit('SET_IS_USER', 2);
						commit('SET_OPENID', statusInfo.openid);
						commit('SET_UNIONID', statusInfo.unionid);
					}else {
						commit('SET_USER', userInfo)
						commit('SET_TOKEN', userInfo.token)
						commit('SET_IS_LOGIN', true)
						commit('SET_IS_USER', 1)
					}
					resolve(res)
				}).catch(err => {
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		// 验证码登录
		codeLogin({commit}, data = {}) {
			return new Promise((resolve, reject) => {
				codeLogin(data).then(res => {
					let userInfo = res.data.userInfo;
					if(userInfo.is_card == 1) {
						commit('SET_USER', userInfo)
						commit('SET_TOKEN', userInfo.token)
						commit('SET_IS_LOGIN', true)
						commit('SET_IS_USER', 1)
					}else {
						commit('SET_USER', userInfo)
						commit('SET_IS_USER', 2)
					}
					resolve(res)
				}).catch(err => {
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		// 账号密码登录
		accountLogin({commit}, data = {}) {
			return new Promise((resolve, reject) => {
				accountLogin(data).then(res => {
					let userInfo = res.data.userInfo;
					if(userInfo.is_card == 1) {
						commit('SET_USER', userInfo)
						commit('SET_TOKEN', userInfo.token)
						commit('SET_IS_LOGIN', true)
						commit('SET_IS_USER', 1)
					}else {
						commit('SET_USER', userInfo)
						commit('SET_IS_USER', 2)
					}
					resolve(res)
				}).catch(err => {
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		// 用户注册
		register({commit}, data = {}) {
			return new Promise((resolve, reject) => {
				register(data).then(res => {
					let userInfo = res.data.userInfo;
					if(userInfo.is_card == 1) {
						commit('SET_USER', userInfo)
						commit('SET_TOKEN', userInfo.token)
						commit('SET_IS_LOGIN', true)
						commit('SET_IS_USER', 1)
					}else {
						commit('SET_USER', userInfo)
						commit('SET_IS_USER', 2)
					}
					resolve(res)
				}).catch(err => {
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		// 静默登录
		defaultLogin({commit},uid) {
			return new Promise((resolve, reject) => {
				defaultLogin(uid).then(res => {
					let userInfo = res.data.userInfo;
					if(userInfo.is_card == 1) {
						commit('SET_USER', userInfo)
						commit('SET_TOKEN', userInfo.token)
						commit('SET_IS_LOGIN', true)
						commit('SET_IS_USER', 1)
					}else {
						commit('SET_USER', userInfo)
						commit('SET_IS_USER', 2)
					}
					// console.log(getToken())
					resolve(res)
				}).catch(err => {
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		updateUser({commit}, data) {
			return new Promise((resolve, reject) => {
				updateUserApi(data).then(res => {
					let statusInfo = res.data.statusInfo || {};
					// let userInfo = res.data.userInfo;
					// if(statusInfo.is_new) {
					// 	commit('SET_IS_USER', 2);
					// 	commit('SET_OPENID', statusInfo.openid);
					// 	commit('SET_UNIONID', statusInfo.unionid);
					// }else {
					// 	commit('SET_USER', userInfo)
					// 	commit('SET_TOKEN', userInfo.token)
					// 	commit('SET_IS_LOGIN', true)
					// 	commit('SET_IS_USER', 1)
					// }
					let userInfo = res.data.userInfo;
					commit('SET_USER', userInfo)
					commit('SET_TOKEN', userInfo.token)
					commit('SET_IS_LOGIN', true)
					commit('SET_IS_USER', 1)
					resolve(res)
				}).catch(err => {
					commit('SET_IS_USER', 2)
					commit('SET_IS_LOGIN', false)
					reject(err)
				})
			})
		},

		outLogin({commit}) {
			return new Promise((resolve, reject) => {
				commit('SET_IS_LOGIN', false)
				commit('SET_TOKEN', '')
				commit('SET_USER', '')
				removeToken()
				removeUser()
				resolve()
			})
		},

		// 获取配置信息
		config({commit}) {
			return new Promise((resolve,reject) => {
				configApi().then(res => {
					let data = res.data;
					commit('SET_CONFIG', data)
					resolve(res)
				}).catch(err => {
					reject(err)
				})
			})

		}
	}
})
