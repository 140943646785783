const TOKEN_KEY = 'YB_TOKEN';
const USER_KEY = 'YB_USER';

/**
 * 获取存储在localStorage中的token
 *
 * @returns 返回存储在localStorage中的token，若不存在则返回null
 */
export function getToken() {
	return localStorage.getItem(TOKEN_KEY);
}

/**
 * 设置 token
 *
 * 将传入的 token 值存入本地存储中，以便后续使用。
 *
 * @param token 要设置的 token 值
 * @returns 无返回值
 */
export function setToken(token) {
	localStorage.setItem(TOKEN_KEY, token);
}

/**
 * 移除 token
 *
 * 从本地存储中移除指定的 token。
 *
 * @returns 无返回值
 */
export function removeToken() {
	localStorage.removeItem(TOKEN_KEY);
}

/**
 * 获取用户信息
 *
 * 从本地存储中获取用户信息，并将其解析为JSON对象返回。
 * 如果本地存储中不存在用户信息，则返回null。
 *
 * @returns 用户信息对象，如果不存在则返回null
 */
export function getUser() {
	let user = localStorage.getItem(USER_KEY) || '';
	return user ? JSON.parse(user) : null;
}

/**
 * 设置用户信息
 *
 * 将传入的用户信息对象序列化为JSON字符串，并存储在localStorage中。
 *
 * @param user 用户信息对象
 * @returns 无返回值
 */
export function setUser(user) {
	localStorage.setItem(USER_KEY, JSON.stringify(user));
}

/**
 * 移除用户信息
 *
 * 从本地存储中移除用户信息，以清除用户登录状态。
 *
 * @returns 无返回值
 */
export function removeUser() {
	localStorage.removeItem(USER_KEY);
}