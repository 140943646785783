var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",class:{
		'animate__animated': _vm.isFixed,
		'animate__fadeInDown': _vm.isFixed,
		'bg-white': _vm.isFixed,
		'bg-transparent': !_vm.isFixed,
		'box-shadow': _vm.isFixed,
		'fixed': _vm.isFixed,
	}},[_c('div',{staticClass:"top-wrapper"},[_c('div',{staticClass:"top flexRow aCenter jBetween"},[_c('div',{staticClass:"flexRow aCenter"},[_c('router-link',{attrs:{"to":{path: '/'}}},[(!_vm.isFixed)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo2.png")}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo1.png")}})]),_c('span',{staticClass:"logo-text",style:({color: !_vm.isFixed ? '#fff' : '#333'})},[_vm._v("云帮大商城 — 让你足不出户，走进临沂商城中国大集")])],1),_c('div',{staticClass:"right flexRow aCenter"},[_c('router-link',{attrs:{"to":{path: '/home/about'}}},[_c('div',{staticClass:"about pointer",class:[_vm.isFixed ? 'theme-color' : 'font-white']},[_vm._v("关于我们")])]),_c('div',{staticClass:"login-reg flexRow aCenter"},[(!_vm.isLogin)?_c('div',{staticClass:"btn pointer",class:[_vm.isFixed ? 'border-theme' : 'border-white'],on:{"click":_vm.login}},[_vm._v("登录")]):_c('div',{staticClass:"btn pointer",class:[_vm.isFixed ? 'border-theme' : 'border-white'],on:{"click":_vm.openUser}},[_vm._v("个人中心")])])],1)])]),_c('div',{staticClass:"menu-box flexRow center"},[_c('div',{staticClass:"menu flexRow aCenter relative"},_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:'menu-'+index,staticClass:"menu-item pointer flex center relative",class:{
						'font-white': !_vm.isFixed,
						'font-black': _vm.isFixed,
					},on:{"click":function($event){return _vm.changeMenu(index, menu)}}},[_c('span',[_vm._v(_vm._s(menu.title))]),(index === _vm.activeIndex)?_c('div',{staticClass:"menu-item_line absolute",class:{
							'line-white': !_vm.isFixed,
							'line-theme': _vm.isFixed
						}}):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }