import request from "@/utils/request";

/**
 * 获取菜单
 */
export function getMenuApi() {
	return request.post('api/nav/index')
}

/**
 * 获取banner
 * @param position 1=首页头部,2=走进临沂,3=商机,4=逛全国，5=pc首页，6=pc首页文章
 * @returns {*}
 */
export function bannerApi(position) {
	return request.post('api/banner/index', {position})
}

/**
 * 获取网站底部信息
 * @returns {*}
 */
export function footerApi() {
	return request.post('api/index/footer')
}

/**
 * 获取配置信息
 * @returns {*}
 */
export function configApi() {
	return request.post('api/config/index')
}

/**
 * 获取短信验证码
 * @param data
 * @returns {void|*}
 */
export function smsCodeApi(data = {}) {
	return request.post('api/sms/send', data)
}

export function pcSmsCodeApi(data = {}) {
	return request.post('api/sms/sendUser', data)
}

export function getRecommendMerchantApi() {
	return request.post('/api/index/merchant')
}

/**
 * 图片上传接口
 * @param data
 * @returns {*}
 */
export function uploadImageApi(data) {
	return request.post('api/ajax/putFile', data)
}