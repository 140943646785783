<template>
	<div class="header" :class="{
		'animate__animated': isFixed,
		'animate__fadeInDown': isFixed,
		'bg-white': isFixed,
		'bg-transparent': !isFixed,
		'box-shadow': isFixed,
		'fixed': isFixed,
	}">

		<div class="top-wrapper">

			<div class="top flexRow aCenter jBetween">

<!--				<div class="logo flex center">logo</div>-->

				<div class="flexRow aCenter">

					<router-link :to="{path: '/'}">
						<img class="logo" v-if="!isFixed" src="@/assets/logo2.png" />

						<img class="logo" v-else src="@/assets/logo1.png" />
					</router-link>

					<span class="logo-text":style="{color: !isFixed ? '#fff' : '#333'}" >云帮大商城 — 让你足不出户，走进临沂商城中国大集</span>

				</div>

				<div class="right flexRow aCenter">

					<router-link :to="{path: '/home/about'}">
						<div class="about pointer" :class="[isFixed ? 'theme-color' : 'font-white']">关于我们</div>
					</router-link>

					<div class="login-reg flexRow aCenter">
<!--						{{$store.getters.isLogin}}-->
						<div
							v-if="!isLogin"
							class="btn pointer"
							:class="[isFixed ? 'border-theme' : 'border-white']"
							@click="login"
						>登录</div>

						<div
								v-else
								class="btn pointer"
								:class="[isFixed ? 'border-theme' : 'border-white']"
								@click="openUser"
						>个人中心</div>

<!--						<div class="btn pointer" :class="[isFixed ? 'border-theme' : 'border-white']">注册</div>-->

					</div>

				</div>

			</div>

		</div>

		<div class="menu-box flexRow center">

			<div class="menu flexRow aCenter relative">

				<div
					class="menu-item pointer flex center relative"
					:class="{
						'font-white': !isFixed,
						'font-black': isFixed,
					}"
					v-for="(menu,index) in menuList"
					:key="'menu-'+index"
					@click="changeMenu(index, menu)"
				>

					<span>{{menu.title}}</span>

					<div
						class="menu-item_line absolute"
						:class="{
							'line-white': !isFixed,
							'line-theme': isFixed
						}"
						v-if="index === activeIndex"
					></div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import mixins from '../mixins'
export default {
	name: "LayoutHeader",
	mixins
}
</script>

<style scoped lang="scss" src="../style.scss"></style>