import LayoutAdmin from "@/layout/layout-admin";

const adminRouter = {
	path: '/admin-layout',
	component: LayoutAdmin,
	redirect: `/admin`,
	children: [
		{
			path: `/admin`,
			component: () => import('@/views/admin/main'),
			name: 'Admin',
			meta: {
				title: '后台管理'
			}
		},
        {
			path: `/admin-user`,
			component: () => import('@/views/admin/user'),
			name: 'AdminUser',
			meta: {
				title: '旺铺修改信息'
			}
		},
        {
			path: `/admin-product-class`,
			component: () => import('@/views/admin/product-class'),
			name: 'AdminProductClass',
			meta: {
				title: '产品分类'
			}
		},
        {
			path: `/admin-product`,
			component: () => import('@/views/admin/product'),
			name: 'AdminProduct',
			meta: {
				title: '产品管理'
			}
		},
        {
			path: `/admin-video`,
			component: () => import('@/views/admin/video'),
			name: 'AdminVideo',
			meta: {
				title: '视频管理'
			}
		},
        {
			path: `/admin-message`,
			component: () => import('@/views/admin/message'),
			name: 'AdminMessage',
			meta: {
				title: '留言管理'
			}
		},
		{
			path: `/admin-overseasBusiness`,
			component: () => import('@/views/admin/overseasBusiness'),
			name: 'overseasBusiness',
			meta: {
				title: '海外商机'
			}
		},
        {
			path: `/admin-nationwide`,
			component: () => import('@/views/admin/nationwide'),
			name: 'AdminNationwide',
			meta: {
				title: '逛全国'
			}
		},
		{
			path: `/admin-openedCustomer`,
			component: () => import('@/views/admin/openedCustomer'),
			name: 'OpenedCustomer',
			meta: {
				title: '已开发客户'
			}
		},
		{
			path: `/admin-taxBureau`,
			component: () => import('@/views/admin/taxation/taxBureau'),
			name: 'TaxBureau',
			meta: {
				title: '税务局'
			}
		},
		{
			path: `/admin-taxCompany`,
			component: () => import('@/views/admin/taxation/taxCompany'),
			name: 'TaxCompany',
			meta: {
				title: '税务公司'
			}
		},
		{
			path: `/admin-proxy`,
			component: () => import('@/views/admin/proxy'),
			name: 'ProxyList',
			meta: {
				title: '代理招募'
			}
		},
        {
			path: `/admin-release`,
			component: () => import('@/views/admin/release'),
			name: 'AdminRelease',
			meta: {
				title: '发布管理'
			}
		},
        {
			path: `/admin-follow`,
			component: () => import('@/views/admin/follow'),
			name: 'AdminFollow',
			meta: {
				title: '我的关注-市场'
			}
		},
        {
			path: `/admin-follow2`,
			component: () => import('@/views/admin/follow2'),
			name: 'AdminFollow2',
			meta: {
				title: '我的关注-活动'
			}
		},
        {
			path: `/admin-supplier`,
			component: () => import('@/views/admin/supplier'),
			name: 'AdminSupplier',
			meta: {
				title: '我的供应商'
			}
		},
        {
			path: `/admin-visitor`,
			component: () => import('@/views/admin/visitor'),
			name: 'AdminVisitor',
			meta: {
				title: '我的访客'
			}
		},
        {
			path: `/admin-footprint`,
			component: () => import('@/views/admin/footprint'),
			name: 'AdminFootprint',
			meta: {
				title: '我的足迹'
			}
		},
        {
			path: `/admin-my-card`,
			component: () => import('@/views/admin/my-card'),
			name: 'AdminMyCard',
			meta: {
				title: '名片夹'
			}
		},
        {
			path: `/admin-openshop`,
			component: () => import('@/views/admin/openshop'),
			name: 'AdminOpenshop',
			meta: {
				title: '开通旺铺'
			}
		},
	]
}

export default adminRouter;