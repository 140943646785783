<template>
	<div class="container flex flexRow">
        <div class="menu-view flex">
            <div class="menu-top-view flex flexRow center">
                云帮大商城后台
            </div>
            <el-menu
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#409EFF"
                router>

                <el-menu-item index="/admin">
                    <i class="el-icon-menu"></i>
                    <span slot="title">首页</span>
                </el-menu-item>

                <el-menu-item index="/admin-openshop" v-if="$store.getters.userinfo && $store.getters.userinfo.is_merchant != 1">
                    <i class="el-icon-menu"></i>
                    <span slot="title">开通旺铺</span>
                </el-menu-item>

                <el-submenu index="/menu1" v-if="$store.getters.userinfo && $store.getters.userinfo.is_merchant == 1">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span>店铺专区</span>
                    </template>

                    <el-submenu index="/menu2">
                        <template slot="title">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span>旺铺管理</span>
                        </template>

                        <el-menu-item index="/admin-user">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">修改信息</span>
                        </el-menu-item>
                        <el-menu-item index="/admin-product-class">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">产品分类</span>
                        </el-menu-item>
                        <el-menu-item index="/admin-product">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">产品管理</span>
                        </el-menu-item>
                        <el-menu-item index="/admin-video">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">视频管理</span>
                        </el-menu-item>
                        <el-menu-item index="/admin-message">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">留言管理</span>
                        </el-menu-item>
                        <!-- <el-menu-item index="/admin-user">
                            <i class="el-icon-menu"></i>
                            <span slot="title">视频教程</span>
                        </el-menu-item> -->
                        <el-menu-item index="/admin-my-card">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">名片夹</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/admin-nationwide">
                        <!-- <i class="el-icon-setting"></i> -->
                        <span slot="title">逛全国</span>
                    </el-menu-item>
					<el-menu-item index="/admin-overseasBusiness">
						<!-- <i class="el-icon-setting"></i> -->
						<span slot="title">海外商机</span>
					</el-menu-item>
					<el-menu-item index="/admin-openedCustomer">
						<!-- <i class="el-icon-setting"></i> -->
						<span slot="title">已开发客户</span>
					</el-menu-item>
					<el-submenu index="menu3" v-if="$store.getters.userinfo && $store.getters.userinfo.is_merchant == 1">
						<template slot="title">
							<!-- <i class="el-icon-menu"></i> -->
							<span>财税专区</span>
						</template>

						<el-menu-item index="/admin-taxBureau">
							<!-- <i class="el-icon-menu"></i> -->
							<span slot="title">税务局</span>
						</el-menu-item>
						<el-menu-item index="/admin-taxCompany">
							<!-- <i class="el-icon-menu"></i> -->
							<span slot="title">税务公司</span>
						</el-menu-item>
					</el-submenu>
					<el-menu-item index="/admin-proxy">
						<!-- <i class="el-icon-setting"></i> -->
						<span slot="title">代理招募</span>
					</el-menu-item>
                    <el-menu-item index="/admin-visitor">
                        <!-- <i class="el-icon-setting"></i> -->
                        <span slot="title">店铺访客</span>
                    </el-menu-item>
                </el-submenu>

<!--				 v-if="$store.getters.userinfo.is_merchant == 1"-->
                <el-submenu index="/menu3">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span>用户专区</span>
                    </template>

                    <el-menu-item index="/admin-release">
                        <!-- <i class="el-icon-menu"></i> -->
                        <span slot="title">发布管理</span>
                    </el-menu-item>

                    <el-submenu index="/menu4">
                        <template slot="title">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span>我的关注</span>
                        </template>
                        <el-menu-item index="/admin-follow">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">市场</span>
                        </el-menu-item>
                        <el-menu-item index="/admin-follow2">
                            <!-- <i class="el-icon-menu"></i> -->
                            <span slot="title">展会</span>
                        </el-menu-item>
                    </el-submenu>

                    <el-menu-item index="/admin-supplier">
                        <!-- <i class="el-icon-setting"></i> -->
                        <span slot="title">我的供应商</span>
                    </el-menu-item>
                    
                    <el-menu-item index="/admin-footprint">
                        <!-- <i class="el-icon-setting"></i> -->
                        <span slot="title">我的足迹</span>
                    </el-menu-item>
                </el-submenu>


            </el-menu>
        </div>
        <div class="content-view flex flex-1">
            <div class="header-view flex flexRow aCenter">
                <!-- <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
                    <el-breadcrumb-item>活动列表</el-breadcrumb-item>
                    <el-breadcrumb-item>活动详情</el-breadcrumb-item>
                </el-breadcrumb> -->
                <div class="flex-1"></div>
                <div class="row flexRow aCenter">

					<div class="flexRow aCenter back pointer" @click="onBack">

						<i class="el-icon-s-home"></i>

						<span>返回门户</span>

					</div>

                    <el-dropdown @command="onUserCommand">
                        <span class="user-name">
                            {{$store.getters.userinfo && $store.getters.userinfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="1">修改密码</el-dropdown-item>
							<el-dropdown-item :command="2" v-if="isPassword === 0">设置密码</el-dropdown-item>
							<el-dropdown-item :command="3">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="cont-router">
                <router-view />
            </div>
        </div>

		<el-dialog
			:visible.sync="showDialog"
			:title="isUpdate?'修改密码':'设置密码'"
			width="800px"
			@close="closeDialog"
		>

			<div class="dialog-box" v-if="isUpdate">

				<el-form ref="updateForm" :model="updateForm" :rules="updateRule">

					<el-form-item label="旧密码：" prop="oldPassword">

						<el-input type="password" v-model="updateForm.oldPassword" placeholder="请输入旧密码"></el-input>

					</el-form-item>

					<el-form-item label="新密码：" prop="newPassword">

						<el-input type="password" v-model="updateForm.newPassword" placeholder="请输入新密码"></el-input>

					</el-form-item>

				</el-form>

			</div>

			<div class="dialog-box" v-else>

				<el-form ref="setForm" :model="setForm" :rules="setRule">

					<el-form-item label="密码：" prop="password">

						<el-input type="password" v-model="setForm.password" placeholder="请输入密码"></el-input>

					</el-form-item>

					<el-form-item label="确认密码：" prop="rePassword">

						<el-input type="password" v-model="setForm.rePassword" placeholder="请确认密码"></el-input>

					</el-form-item>

				</el-form>

			</div>

			<template slot="footer">

				<div class="flexRow aCenter jEnd">

					<el-button @click="closeDialog">取消</el-button>

					<el-button type="primary" @click="onSubmit">确定</el-button>

				</div>

			</template>

		</el-dialog>

	</div>
</template>

<script>
import mixins from '../mixins'
export default {
	name: "LayoutAdmin",
	mixins,
	components: {
		
	}
}
</script>

<style scoped lang="scss" src="../style.scss"></style>