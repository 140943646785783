import LayoutMain from "@/layout/layout-main";

const routePer = 'market'

const marketRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `/home/${routePer}list`,
			component: () => import('@/views/market/list'),
			name: 'MarketList',
			meta: {
				title: '市场列表'
			}
		},
		{
			path: `/home/${routePer}detail`,
			component: () => import('@/views/market/detail'),
			name: 'MarketDetail',
			meta: {
				title: '市场详情'
			}
		}
	]
}

export default marketRouter;