export default {
    /**
     * 检测手机号
     * @param phone 手机号
     * @returns {boolean}   合法为true
     */
    regPhone(phone) {
        phone = phone || '';
        let reg = /^1[3456789]\d{9}$/;
        return reg.test(phone.trim());
    }
}
