<template>
	<div class="container relative" ref="container" :style="{height: pageHeight + 'px'}" @scroll="handleScroll">

		<layout-header :isFixed="isFixed" @login="openLoginDialog" />

		<div class="main" :style="{minHeight: mainHeight+'px'}">

			<keep-alive exclude="NewsList,NewsDetail,BusinessDetail,SchoolDetail,LinyiDetail,productList,productDetail">
				<router-view />
			</keep-alive>

		</div>

		<layout-footer />

		<el-dialog
			:visible.sync="loginDialog"
			title="微信登录"
			width="30%"
		>

			<div class="login-body flex center">

				<wx-login
					:appid="wxAppId"
					scope="snsapi_login"
					:redirect_uri="encodeURI(wxRedirectUri)"
					theme="black"
					:href="wxHref"
				/>

				<span class="tip-text">请使用微信扫码登录</span>

			</div>

		</el-dialog>

		<el-dialog
			:visible.sync="userDialog"
			title="完善用户信息"
			width="800px"
			@close="closeUserDialog"
		>

			<el-form ref="form" :model="form" :rules="rules" label-width="100px">

				<el-form-item label="上传头像：" prop="avatar">

					<layout-upload
						:urls.sync="form.avatar"
						:accept="'.png,.jpg,.jpeg'"
						:maxCount="1"
						:fileSizeLimit="20 * 1024"
						:info="'请上传图片，不得超过20M，仅支持.png、.jpg格式'"
						baseUrl="http://yunbang.oss-cn-hangzhou.aliyuncs.com"
					></layout-upload>

				</el-form-item>

				<el-form-item label="姓名：" prop="nickname">
					<el-input v-model="form.nickname" placeholder="请输入姓名"></el-input>
				</el-form-item>

				<el-form-item label="手机号码：" prop="mobile">
					<el-input v-model="form.mobile" placeholder="请输入手机号码"></el-input>
				</el-form-item>

				<el-form-item label="验证码：" prop="captcha">
					<el-input v-model="form.captcha" placeholder="请输入短信验证码">
						<template #append>
							<el-button class="append-btn" type="primary" @click="onSmsCode">{{smsText}}</el-button>
						</template>
					</el-input>
				</el-form-item>

				<el-form-item label="所在地：" prop="addressArr">
					<el-cascader
						ref="cascader"
						style="width: 100%;"
						v-model="form.addressArr"
						:props="props"
						clearable
						@change="changeAddress"
					></el-cascader>
				</el-form-item>

			</el-form>

			<div id="card-img" ref="cardImg" class="card-img">

				<div class="card-img-inner">

					<img class="card-bg" src="@/assets/images/mp_1.png"  alt=""/>

					<div class="card-name">
						<div class="line2">{{ form.nickname }}</div>
					</div>

					<div class="card-info">

						<div class="card-floor">

							<img class="icon" src="@/assets/images/phone_1.png"  alt=""/>

							<span>{{ form.mobile }}</span>

						</div>

						<div class="card-floor">

							<img class="icon" src="@/assets/images/address_1.png"  alt=""/>

							<div class="flex-1 line2">{{ address }}</div>

						</div>

					</div>

				</div>

			</div>

			<template #footer>

				<div class="flexRow aCenter jEnd">

					<el-button @click="closeUserDialog">取消</el-button>

					<el-button type="primary" @click="onSubmit">提交</el-button>

				</div>

			</template>

		</el-dialog>

	</div>
</template>

<script>
import LayoutHeader from "../components/layout-header";
import LayoutFooter from "../components/layout-footer";
import LayoutUpload from "@/components/LayoutUpload/index.vue";

import WxLogin from 'vue-wxlogin'

import mixins from '../mixins'
export default {
	name: "LayoutMain",
	mixins,
	components: {
		LayoutUpload,
		LayoutHeader,
		LayoutFooter,
		WxLogin
	}
}
</script>

<style scoped lang="scss" src="../style.scss"></style>