import {getArea} from "@/api/admin";
import setting from "@/setting";
import test from "@/utils/test";
import {pcSmsCodeApi} from "@/api/comm";
import html2canvas from 'html2canvas'
import upload from "@/utils/oss";
import util from "@/utils/util";


export default {
	data() {
		return {
			smsDisabled: false,
			smsSecond: setting.countDown,
			smsText: '发送验证码',
			form: {},
			props: {
				value: 'id',
				label: 'name',
				lazy: true,
				lazyLoad: (node, resolve) => {
					const { level } = node;
					// console.log('level ===', level, node)
					this.getAddress(level === 0 ? 0 : node.value, level, resolve)
				}
			}
		}
	},

	created() {
		// console.log(this.$route)
	},

	methods: {

		onSubmit() {
			console.log('onSubmit')
			this.$refs.form.validate(valid => {
				console.log(valid)
				// this.form.id = this.$store.getters.userinfo.id;
				let element = this.$refs.cardImg;
				html2canvas(element).then(canvas => {
					let img = canvas.toDataURL('image/png');
					let now = new Date().getTime();
					let filename = `cardImage-${now}.png`
					let file = util.base64ToFile(img, filename)
					// console.log(file)
					upload(file).then(res => {
						this.form.card_img = res;
						this.form.openid = this.$store.getters.openid;
						this.form.unionid = this.$store.getters.unionid;
						this.$store.dispatch('updateUser', this.form).then(res => {
							window.location.href = setting.wxRedirectUri
						})
					})
				})

			})

		},

		changeAddress(e) {
			console.log(e)
			console.log(this.form)
			this.$nextTick(() => {
				let checkedNodes = this.$refs.cascader.getCheckedNodes()[0].pathNodes;
				console.log(checkedNodes)
				this.form.province = checkedNodes[0].label;
				this.form.province_id = checkedNodes[0].value;
				this.form.city = checkedNodes[1].label;
				this.form.city_id = checkedNodes[1].value;
				this.form.area = checkedNodes[2].label;
				this.form.area_id = checkedNodes[2].value;
				this.address = checkedNodes.map(item => item.label).join('');
			})
		},

		onSmsCode() {
			if(!test.regPhone(this.form.mobile)) return this.$message.error('请输入正确的手机号码');
			this.smsDisabled = true;

			pcSmsCodeApi({
				mobile: this.form.mobile,
				template_code: 'user_pc_register'
			}).then(({code, data, msg}) => {
				this.$message.success(msg);
				let second = setting.countDown;
				this.smsText = `${second}s`;
				let timer = setInterval(() => {
					second--;
					this.smsText = `${second}s`;
					if(second <= 0){
						clearInterval(timer);
						this.smsDisabled = false;
						this.smsText = '重新发送';
					}
				}, 1000);
			}).catch(err => {
				this.$message.error(err.msg);
			}).finally(() => {
				this.smsDisabled = false;
			})
		},

		getAddress(pid, level, resolve) {
			getArea({pid}).then(res => {
				let data = res.data || [];
				data.forEach(item => {
					item.leaf = level >= 2
				})
				resolve(data)
			})
		}
	}
}