import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'

import image from '@/utils/image';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/styles/index.scss";
import 'animate.css'

Vue.config.productionTip = false

Vue.prototype.$image = image;

Vue.use(ElementUI);
Vue.use(Meta);

new Vue({
	data: {
		title: '云帮大商城',
		keywords: '云帮, 云帮大商城, 云帮商城, 云帮商城系统, 云帮商城系统源',
		description: '云帮大商城，临沂商城，中国大集'
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'keywords',
					content: this.keywords
				},
				{
					name: 'description',
					content: this.description
				}
			]
		}
	},
	router,
	store,
	render: h => h(App)
}).$mount('#app')
