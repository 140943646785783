import LayoutMain from "@/layout/layout-main";

const routePer = '/chamber'

const chamberRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/chamber/list'),
			name: 'ChamberList',
			meta: {
				title: '商会列表'
			}
		},
		{
			path: `${routePer}/detail`,
			component: () => import('@/views/chamber/detail'),
			name: 'ChamberDetail',
			meta: {
				title: '商会详情'
			}
		}
	]
}

export default chamberRouter;