import {footerApi} from "@/api/comm";

export default {
	data() {
		return {
			info: {
				about: {}
			}
		}
	},

	mounted() {
		this.getInfo();
	},

	methods: {
		getInfo() {
			footerApi().then(res => {
				let data = res.data || [];
				if(data.about && data.about.gzh) {
					data.about.gzh = this.$image.handleImage(data.about.gzh)
				}
				if(data.about && data.about.xcx) {
					data.about.xcx = this.$image.handleImage(data.about.xcx)
				}
				this.info = res.data || {};
			})
		},

		openHref(item) {
			if(item.url) {
				window.open(item.url);
			}
		}
	}
}