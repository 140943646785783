import OSS from 'ali-oss'
import { v4 as uuid } from "@lukeed/uuid";
import moment from 'moment'

const client = new OSS({
    // https://yunbang.oss-cn-hangzhou.aliyuncs.com/
    // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: 'oss-cn-hangzhou',
    // 从环境变量中获取访问凭证
    // 当然，以下两个也可以直接写死
    accessKeyId: 'LTAI5tJipvd4NHzfgiKuBBhi',
    accessKeySecret: 'C5mBk7n4L4l0kd6wPu4bgiOpaHm8uh',
    // 填写Bucket名称。
    bucket: 'yunbang'
});

export default async function upload (fileObj) {
    // console.log('fileObj = ', fileObj)
    const directory = '/storage/default/' + moment().format('YYYYMMDD') + '/'; // 上传路径 
    let name = directory + 'yunbangweb-' + uuid() + '.' + fileObj.name.substring(fileObj.name.lastIndexOf(".") + 1)
    return new Promise((resolve,reject) => {
        client  
            .put(name, fileObj)  
            .then((result) => {  
                // this.$message.success('上传成功！');  
                // 这里你可以获取到上传后的文件地址，并根据需要进行处理  
                const fileUrl = result.url;  
                console.log(fileUrl);
                setTimeout(() => {
                    resolve(fileUrl)
                }, 10);
            })  
            .catch((err) => {  
                console.error(err);  
                // this.$message.error('上传失败！');  
                setTimeout(() => {
                    reject()
                }, 10);
            }); 
    })
} 