import menuList from "../data/menuList";
import {getMenuApi} from '@/api/comm'
import {mapGetters} from 'vuex'

export default {
	data() {
		return {
			activeIndex: 0,
			menuList: menuList(),
		}
	},

	computed: mapGetters(['isLogin']),

	watch: {
		'$route'(to, from) {
			this.activeIndex = this.$route.path === "/" ? 0 : this.menuList.findIndex(item => this.$route.path.indexOf(item.routeKey) > 0 );
		}
	},

	created() {
		this.getMenu();
	},

	methods: {

		openUser() {
			if(!this.isLogin) return this.$message.error('登录过期，请重新登录');
			this.$router.push({
				path: '/admin'
			})
		},

		login() {
			this.$emit('login')
		},

		changeMenu(index, item) {
			if(item.type === 2) {
				window.open(item.path)
			}else if(item.type === 1) {
				this.activeIndex = index;
				if(item.path === this.$route.path) return;
				if(item.path) {
					this.$router.push(item.path);
				}
			}
		},

		formatMenu(list) {
			return list.map(item => {
				return {
					title: item.title,
					path: item.pc_link_url,
					type: item.type,
					routeKey: this.formatRouterKey(item.pc_link_url, item.type),
				}
			})
		},

		formatRouterKey(url, type) {
			if(type !== 1) return ''
			// console.log('11111', url.split('/'))
			return url.split('/')[1]
		},

		getMenu() {
			getMenuApi().then(res => {
				let list = res.data || [];
				let menu = this.formatMenu(list);
				this.menuList = this.menuList.concat(menu);
				this.activeIndex = this.$route.path === "/" ? 0 : this.menuList.findIndex(item => this.$route.path.indexOf(item.routeKey) > 0);
				console.log(this.menuList)

			})
		}
	}
}