import LayoutMain from "@/layout/layout-main";

const routePer = '/school'

const schoolRouter = {
	path: routePer,
	component: LayoutMain,
	redirect: `${routePer}/list`,
	children: [
		{
			path: `${routePer}/list`,
			component: () => import('@/views/school/list'),
			name: 'SchoolList',
			meta: {
				title: '商学院'
			}
		},
		{
			path: `${routePer}/detail`,
			component: () => import('@/views/school/detail'),
			name: 'SchoolDetail',
			meta: {
				title: '商学院'
			}
		}
	]
}

export default schoolRouter;